export function trackAddedToCart(lineItem, quantity, productUrl, checkoutUrl) {
  let klaviyo = window.klaviyo || []

  const product = lineItem.variant.product;
  const productId =  product.id.substring(product.id.lastIndexOf('/') + 1)
  const itemTitle = lineItem.title

  let data = {
    $value: quantity * Number(lineItem.variant.priceV2.amount),
    AddedItemProductName: itemTitle,
    AddedItemProductID: productId,
    AddedItemSKU: lineItem.variant.sku,
    AddedItemImageURL: lineItem.variant.image.transformedSrc,
    AddedItemURL: productUrl,
    AddedItemPrice: lineItem.variant.priceV2.amount,
    AddedItemQuantity: quantity,
    ItemNames: [itemTitle],
    CheckoutURL: checkoutUrl,
    Items: [{
      ProductID: productId,
      ImageURL: lineItem.variant.image.transformedSrc,
      Handle: product.handle,
      Brand: product.vendor,
      Price: lineItem.variant.priceV2.amount,
      SKU: lineItem.variant.sku,
      ProductName: itemTitle,
      Quantity: quantity,
      ItemPrice: lineItem.variant.priceV2.amount,
      RowTotal: lineItem.variant.priceV2.amount,
      ProductURL: productUrl,
    }]
  }

  console.log(data)

  klaviyo.push(['track', 'Added to Cart V2', data])
}
